<template>
  <div
    :class="[
      'rounded-10 flex flex-row justify-between p-3 border hover:shadow-sidebar cursor-pointer w-full transition-all duration-300',
      minus === '-' ? 'border-yellow' : 'border-primary',
      (id === cardActive && minus === '-') && 'bg-yellow text-black',
      (id === cardActive && minus !== '-') && 'bg-primary text-white',
    ]"
  >
    <div class="grid">
      <p class="font-bold text-base">{{ tLU }}</p>
      <p class="font-medium text-xs">{{ tLC }}</p>
      <p class="font-semibold text-xs">{{ tLB }}</p>
    </div>
    <div class="grid text-right">
      <p
        :class="[
          'font-bold text-base',
          
        ]"
      >
        {{ tRU | toCurrency }}
      </p>
      <p class="font-medium text-xs">{{ tRC }}</p>
      <p class="font-semibold text-xs mt-auto">{{ tRB }}</p>
    </div>
  </div>
</template>

<script>
import { NumberFormat } from '../utils';
export default {
  name: 'RecentTransactions',
  props: {
    id: String,
    minus: String,
    cardActive: String,
    tLU: {
      type: String,
      default: '',
      required: true,
    },
    tLC: {
      type: String,
      default: '',
      required: false,
    },
    tLB: {
      type: String,
      default: '',
      required: true,
    },
    tRU: {
      type: Number,
      required: false,
    },
    tRC: {
      type: String,
      default: '',
      required: false,
    },
    tRB: {
      type: String,
      default: 'TripwePay',
      required: false,
    },
  },
  methods: {
    numberFormat: num => NumberFormat(num),
  },
};
</script>
