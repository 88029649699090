var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'rounded-10 flex flex-row justify-between p-3 border hover:shadow-sidebar cursor-pointer w-full transition-all duration-300',
    _vm.minus === '-' ? 'border-yellow' : 'border-primary',
    (_vm.id === _vm.cardActive && _vm.minus === '-') && 'bg-yellow text-black',
    (_vm.id === _vm.cardActive && _vm.minus !== '-') && 'bg-primary text-white',
  ]},[_c('div',{staticClass:"grid"},[_c('p',{staticClass:"font-bold text-base"},[_vm._v(_vm._s(_vm.tLU))]),_c('p',{staticClass:"font-medium text-xs"},[_vm._v(_vm._s(_vm.tLC))]),_c('p',{staticClass:"font-semibold text-xs"},[_vm._v(_vm._s(_vm.tLB))])]),_c('div',{staticClass:"grid text-right"},[_c('p',{class:[
        'font-bold text-base',
        
      ]},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.tRU))+" ")]),_c('p',{staticClass:"font-medium text-xs"},[_vm._v(_vm._s(_vm.tRC))]),_c('p',{staticClass:"font-semibold text-xs mt-auto"},[_vm._v(_vm._s(_vm.tRB))])])])
}
var staticRenderFns = []

export { render, staticRenderFns }