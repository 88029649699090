<template>
  <transition name="slide">
    <fragment>
      <div class="col-span-5 md:col-span-2">
        <div class="sm:flex justify-between">
          <h1
            class="font-semibold text-xl text-primary leading-9 -mb-3 whitespace-nowrap"
          >
            Recent Transactions
          </h1>
          <div class="flex space-x-3 my-4 sm:my-0">
            <v-date-picker
              color="green"
              class="inline h-full"
              :masks="{ input: 'DD / M / YYYY' }"
              v-model="date.awal"
              @input="getHistoryTransactions"
              :maxDate="date.akhir"
              :is-required="true"
            >
              <template v-slot="{ inputValue, togglePopover }">
                <div
                  class="flex space-x-2 justify-center items-center bg-f5 rounded px-3 py-1 cursor-pointer group"
                  @click="togglePopover()"
                >
                  <img src="../../assets/icons/ic-calendar.svg" alt="" />
                  <input
                    class="text-black text-xs font-semibold bg-f5 whitespace-nowrap leading-none -mb-ii outline-none group-hover:text-primary w-16 cursor-pointer"
                    :value="inputValue"
                    disabled
                  />
                </div>
              </template>
            </v-date-picker>
            <v-date-picker
              color="green"
              class="inline h-full"
              :masks="{ input: 'DD / M / YYYY' }"
              v-model="date.akhir"
              @input="getHistoryTransactions"
              :minDate="date.awal"
              :maxDate="maxDate"
              :is-required="true"
            >
              <template v-slot="{ inputValue, togglePopover }">
                <div
                  class="flex space-x-2 justify-center items-center bg-f5 rounded px-3 py-1 cursor-pointer group"
                  @click="togglePopover()"
                >
                  <img src="../../assets/icons/ic-calendar.svg" alt="" />
                  <input
                    class="text-black text-xs font-semibold bg-f5 whitespace-nowrap leading-none -mb-ii outline-none group-hover:text-primary w-16 cursor-pointer"
                    :value="inputValue"
                  />
                </div>
              </template>
            </v-date-picker>
          </div>
        </div>
        <div class="h-76.5vh overflow-y-scroll" v-if="!loadingRecent">
          <div
            class="mt-8 text-center text-lg font-bold"
            v-if="recents.length <= 0"
          >
            <span>No Balance transaction history</span>
          </div>
          <div v-else>
            <div v-for="(datas, index) in recents" :key="index">
              <p
                class="font-semibold text-base text-primary mb-2 mt-5 capitalize"
              >
                {{ datas.header }}
              </p>
              <div class="space-y-3 md:space-y-5">
                <div
                  v-for="item in datas.data"
                  :key="item.id"
                  @click="getTransactionDetails(item)"
                >
                  <RecentTransactions
                    :tLU="item.tipe"
                    :tLC="item.transaksi"
                    :tLB="item.tanggal.split(' ').pop()"
                    :tRU="item.nominal"
                    :tRB="item.trans"
                    :minus="String(item.nominal).slice(0, 1)"
                    :id="String(item.id)"
                    :cardActive="String(active)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="h-72vh overflow-y-scroll mt-8" v-else>
          <div class="space-y-3 md:space-y-5">
            <div
              class="rounded-10 h-5 hover:shadow-sidebar bg-gray-200 cursor-pointer w-28 transition-all duration-300 animate-pulse"
            ></div>
            <div
              class="rounded-10 flex flex-row justify-between p-3 h-14 hover:shadow-sidebar bg-gray-200 cursor-pointer w-full transition-all duration-300 animate-pulse"
            ></div>
            <div
              class="rounded-10 flex flex-row justify-between p-3 h-14 hover:shadow-sidebar bg-gray-200 cursor-pointer w-full transition-all duration-300 animate-pulse"
            ></div>
          </div>
          <div class="space-y-3 md:space-y-5 mt-9">
            <div
              class="rounded-10 h-5 hover:shadow-sidebar bg-gray-200 cursor-pointer w-48 transition-all duration-300 animate-pulse"
            ></div>
            <div
              class="rounded-10 flex flex-row justify-between p-3 h-14 hover:shadow-sidebar bg-gray-200 cursor-pointer w-full transition-all duration-300 animate-pulse"
            ></div>
            <div
              class="rounded-10 flex flex-row justify-between p-3 h-14 hover:shadow-sidebar bg-gray-200 cursor-pointer w-full transition-all duration-300 animate-pulse"
            ></div>
            <div
              class="rounded-10 flex flex-row justify-between p-3 h-14 hover:shadow-sidebar bg-gray-200 cursor-pointer w-full transition-all duration-300 animate-pulse"
            ></div>
            <div
              class="rounded-10 flex flex-row justify-between p-3 h-14 hover:shadow-sidebar bg-gray-200 cursor-pointer w-full transition-all duration-300 animate-pulse"
            ></div>
            <div
              class="rounded-10 flex flex-row justify-between p-3 h-14 hover:shadow-sidebar bg-gray-200 cursor-pointer w-full transition-all duration-300 animate-pulse"
            ></div>
          </div>
        </div>
      </div>
      <div class="col-span-5 md:col-span-2" v-if="transactionDetails">
        <div
          class="bg-white rounded-10 shadow-sidebar w-full transition-all duration-300"
        >
          <div class="flex flex-col space-y-8 bg-primary rounded-10 p-6">
            <h1 class="font-medium text-sm text-white">Detail Transactions</h1>
            <div
              class="flex flex-col space-y-1 w-full items-center justify-center"
            >
              <img src="../../assets/icons/ic-success.svg" alt="" />
              <span class="font-semibold text-base text-white"
                >Transfer Amount</span
              >
              <span class="font-bold text-3xl text-white">{{
                positiveNominal(transactionDetails.nominal) | toCurrency
              }}</span>
            </div>
          </div>
          <div class="px-7 py-8">
            <div class="table-auto">
              <table class="w-full">
                <!-- <tr>
                  <td class="whitespace-nowrap font-medium text-sm py-2">Transfer Amount</td>
                  <td class="whitespace-nowrap font-medium text-sm py-2">: {{ numberFormat(transactionDetails.nominal) !== '' ? numberFormat(transactionDetails.nominal) : 'Rp 0' }}</td>
                </tr> -->
                <tr>
                  <td class="whitespace-nowrap font-medium text-sm py-2">
                    Transaction Date
                  </td>
                  <td class="whitespace-nowrap font-medium text-sm py-2">
                    : {{ transactionDetails.tanggal }}
                  </td>
                </tr>
                <tr v-if="transactionDetails.tipe != ''">
                  <td class="whitespace-nowrap font-medium text-sm py-2">
                    Transaction Type
                  </td>
                  <td class="whitespace-nowrap font-medium text-sm py-2">
                    : {{ transactionDetails.tipe }}
                  </td>
                </tr>
                <!-- <tr>
                  <td class="whitespace-nowrap font-medium text-sm py-2">Transaction Destination</td>
                  <td class="whitespace-nowrap font-medium text-sm py-2">: {{ transactionDetails.transaksi !== '' ? transactionDetails.transaksi : '. . .' }}</td>
                </tr> -->
                <tr v-if="transactionDetails.reff != ''">
                  <td class="whitespace-nowrap font-medium text-sm py-2">
                    Reff
                  </td>
                  <td class="whitespace-nowrap font-medium text-sm py-2">
                    :
                    {{
                      transactionDetails.reff !== ""
                        ? transactionDetails.reff
                        : ". . ."
                    }}
                  </td>
                </tr>
                <!-- <tr v-if="transactionDetails.transaksi != ''">
                  <td class="whitespace-nowrap font-medium text-sm py-2">Recepient Name </td>
                  <td class="whitespace-nowrap font-medium text-sm py-2">: {{ transactionDetails.transaksi !== '' ? transactionDetails.transaksi : '. . .' }}</td>
                </tr> -->
                <tr>
                  <td class="whitespace-nowrap font-medium text-sm py-2">
                    Note
                  </td>
                  <td class="whitespace-nowrap font-medium text-sm py-2 truncate">
                    :
                    {{
                      transactionDetails.ket != ""
                        ? transactionDetails.ket
                        : "-"
                    }}
                  </td>
                </tr>
                <tr
                  v-if="
                    transactionDetails.invoice != null &&
                    transactionDetails.invoice != ''
                  "
                >
                  <td class="whitespace-nowrap font-medium text-sm py-2">
                    Booking Id
                  </td>
                  <td class="whitespace-nowrap font-medium text-sm py-2">
                    :
                    {{
                      transactionDetails.invoice != ""
                        ? transactionDetails.invoice
                        : ". . ."
                    }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div
        class="hidden md:block col-span-5 md:col-span-2"
        v-else-if="!loadingRecent"
      >
        <div
          class="bg-white rounded-10 shadow-sidebar w-full transition-all duration-300"
        >
          <div class="flex flex-col space-y-8 bg-primary rounded-10 p-6">
            <h1 class="font-medium text-sm text-white">Detail Transactions</h1>
            <span class="font-bold text-3xl text-white text-center"
              >No transaction selected</span
            >
          </div>
          <div
            class="flex flex-col p-10 pt-16 space-y-5 items-center justify-center w-full"
          ></div>
        </div>
      </div>
      <div class="col-span-5 md:col-span-2" v-else>
        <div
          class="bg-white rounded-10 shadow-sidebar w-full transition-all duration-300"
        >
          <div class="flex flex-col space-y-8 bg-primary rounded-10 p-6">
            <div
              class="rounded-10 h-5 hover:shadow-sidebar bg-gray-200 cursor-pointer w-40 transition-all duration-300 animate-pulse"
            ></div>
            <div class="flex justify-center">
              <div
                class="rounded-10 h-20 hover:shadow-sidebar bg-gray-200 cursor-pointer w-36 transition-all duration-300 animate-pulse"
              ></div>
            </div>
          </div>
          <div
            class="flex flex-col p-10 pt-16 space-y-5 items-center justify-center w-full"
          >
            <img
              src="../../assets/icons/img-alt.svg"
              class="animate-bounce"
              alt="Pilih transaksi"
            />
            <div
              class="rounded-10 h-7 hover:shadow-sidebar bg-gray-200 cursor-pointer w-40 transition-all duration-300 animate-pulse"
            ></div>
          </div>
        </div>
      </div>
    </fragment>
  </transition>
  <!-- <detail-transaction ref="detailTransaction"></detail-transaction> -->
</template>

<script>
import RecentTransactions from "../../components/RecentTransactions";
import { NumberFormat } from "../../utils";
import Vue from "vue";

const date = new Date();
const firstDay = new Date(date.getFullYear(), date.getMonth(), 2);
const firstDate = firstDay.toISOString().slice(0, 10);

export default {
  name: "Transactions",
  components: {
    RecentTransactions,
    DetailTransaction: () =>
      import("@/components/layout/DetailTransaction.vue"),
  },
  data: () => ({
    recents: null,
    loadingRecent: false,
    date: {
      awal: firstDate,
      akhir: new Date().toISOString().slice(0, 10),
    },
    maxDate: date,
    transactionDetails: null,
    active: null,
  }),
  methods: {
    showCurrent() {},
    numberFormat: (num) => NumberFormat(num),
    positiveNominal: (num) => Math.abs(num),
    async getHistoryTransactions() {
      this.loadingRecent = true;
      try {
        this.$store.commit("setOverlayLoading", true);
        const res = await this.$http.post(
          `${this.$apiTripweWeb}/tripwepay/history`,
          {
            start: 0,
            end: "",
            awal: new Date(this.date.awal).toISOString().slice(0, 10),
            akhir: new Date(this.date.akhir).toISOString().slice(0, 10),
            cari: "",
          }
        );
        const data = res.data.response;
        this.recents = data;
        this.loadingRecent = false;
        if (data === undefined || data.length === 0) {
          this.transactionDetails = null;
          this.$toasted.global.success("No Balance transaction history");
        } else {
          if (data.length > 0) {
            await this.getTransactionDetails(data[0].data[0]);
          }
        }
      } catch (error) {
        this.$toasted.global.error("Fail to load recent transaction");
      } finally {
        this.loadingRecent = false;
        this.$store.commit("setOverlayLoading", false);
      }
    },
    // async showDetailTransaction(detail) {
    //   await this.$refs.detailTransaction.open({ data: detail });
    // },
    async getTransactionDetails(data) {
      this.transactionDetails = data;
      this.setActive(data.id);
    },
    setActive(active) {
      return (this.active = active);
    },
  },

  created() {
    this.getHistoryTransactions();
    this.$store.commit("setBreadcrumbItems", [
      { text: "Home", routeName: "Home" },
      { text: "Balance", routeName: "TripwePay" },
      { text: "Transactions", routeName: "Transactions" },
    ]);
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    transactionDetailsPaymentMethod() {
      return "Balance";
    },
  },
};
</script>
